import {
  FiFacebook as FacebookIcon,
  FiInstagram as InstagramIcon,
  FiMail as MailIcon,
  FiTwitter as TwitterIcon,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import React from 'react';

import config from '../../site-config';
import Logo from '../assets/svg/Logo';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="container px-4 xl:px-0 mx-auto py-16">
      <div
        className="
          flex
          flex-col-reverse
          justify:center
          lg:flex-row
          lg:justify-between
        "
      >
        <div
          className="flex flex-col my-16 lg:mt-0 items-center lg:items-start"
        >
          <Logo />
          <div className="flex mt-4 justify-center text-gray-500">
            {t('katapultaRights', { year: new Date().getFullYear() })}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-center text-gray-800">
            <a href="mailto:hola@taggi.app">
              <MailIcon
                className="mx-2 text-primary"
                size="2em"
              />
            </a>
            {config.facebookPage && (
              <a href={`https://facebook.com/${config.facebookPage}`}>
                <FacebookIcon
                  className="mx-2 text-primary"
                  size="2em"
                />
              </a>
            )}
            {config.instagramPage && (
              <a href={`https://www.instagram.com/${config.instagramPage}`}>
                <InstagramIcon
                  className="mx-2 text-primary"
                  size="2em"
                />
              </a>
            )}
            {config.twitterPage && (
              <a href={`https://twitter.com/${config.twitterPage}`}>
                <TwitterIcon
                  className="mx-2 text-primary"
                  size="2em"
                />
              </a>
            )}
          </div>
          <div className="flex flex-col mt-4 items-center lg:items-left">
            <a className="mx-2" href="https://katapulta.co/es/terminos/">
              {t('termsConditions')}
            </a>
            <a className="mx-2" href="https://katapulta.co/es/privacidad/">
              {t('privacyPolicy')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
