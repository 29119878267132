module.exports = {
  apiHostname: 'api.taggi.app',
  apiVersion: 'v1',
  appDescription: 'Taggi conecta objetos y mascotas con personas',
  appKeywords: ['taggi', 'objetos', 'perdidos', 'encontrar'],
  appName: 'Taggi',
  appStoreLink: 'https://apps.apple.com/es/app/taggi/id1511311213',
  appUrl: 'https://taggi.app',
  author: '@KatapultaCo',
  bodyBackgroundColor: '#ffffff',
  dateFormat: {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
    year: 'numeric',
  },
  emailAddress: 'hola@katapulta.es',
  facebookPage: 'taggiapp-103897381331336',
  googleAnalyticsID: 'G-QP7FFHY5LQ',
  instagramPage: 'taggi.app',
  pathPrefix: '/',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=co.katapulta.taggi',
  primaryColor: '#269aff',
  recaptchaSiteKey: '6LeiH6cZAAAAABN8llBkCWCy-UYmMNeUvm-woM6h',
  secondaryColor: '#254CA5',
  twitterPage: 'AppTaggi',
};
