import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';

import esLogo from '../assets/images/es.png';
import enLogo from '../assets/images/en.png';
import frLogo from '../assets/images/fr.png';
import itLogo from '../assets/images/it.png';
import deLogo from '../assets/images/de.png';
import czLogo from '../assets/images/cz.png';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('');

  useEffect(
    () => {
      setLanguage(i18n.languages[0]);
    },
    [i18n.language],
  );

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);

    setLanguage(event.target.value);
  };

  return (
    <Select
      classes={{
        select: 'ml-8',
      }}
      disableUnderline
      inputProps={{
        name: 'language',
      }}
      MenuProps={{
        MenuListProps: {
          disablePadding: true,
        },
      }}
      onChange={(e) => handleChange(e)}
      value={language}
    >
      <MenuItem value="en">
        <img alt="EN" className="w-5" src={enLogo} />
      </MenuItem>
      <MenuItem value="es">
        <img alt="ES" className="w-5" src={esLogo} />
      </MenuItem>
      <MenuItem value="fr">
        <img alt="FR" className="w-5" src={frLogo} />
      </MenuItem>
      <MenuItem value="it">
        <img alt="IT" className="w-5" src={itLogo} />
      </MenuItem>
      <MenuItem value="de">
        <img alt="DE" className="w-5" src={deLogo} />
      </MenuItem>
      <MenuItem value="cz">
        <img alt="CZ" className="w-5" src={czLogo} />
      </MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
