import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    error: {
      main: red.A400,
    },
    primary: {
      contrastText: '#ffffff',
      dark: '#004e92',
      light: '#92ccfe',
      main: '#269aff',
    },
    secondary: {
      main: '#0069ff',
    },
  },
  typography: {
    fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
  },
});

export default theme;
