import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from '../assets/svg/Logo';
import LanguageSwitcher from './LanguageSwitcher';

const Header = ({ children }) => (
  <header className="sticky top-0 bg-white shadow z-10">
    <div
      className={
        `container
        flex
        sm:flex-row
        justify-between
        items-center
        mx-auto py-4 px-8`
      }
    >
      <Link to="/">
        <div className="flex items-center text-2xl text-black">
          <Logo enableColor />
        </div>
      </Link>
      <div className="flex hidden mt-4 sm:mt-0 sm:block">
        {children}
      </div>
      <LanguageSwitcher />
    </div>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
