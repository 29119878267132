import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import Header from './Header';
import Footer from './Footer';
import theme from '../theme/material';

import '../i18n/i18n';

const Layout = ({ children, headerContent }) => (
  <ThemeProvider theme={theme}>
    <Header>
      {headerContent}
    </Header>
    <main className="text-gray-900">
      {children}
    </main>
    <Footer />
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerContent: PropTypes.node,
};

Layout.defaultProps = {
  headerContent: null,
};

export default Layout;
