import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import de from './de.json';
import cs from './cs.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: {
      checkForSimilarInWhitelist: false,
    },
    fallbackLng: ['en'],
    interpolation: {
      escapeValue: false,
    },
    load: 'languageOnly',
    resources: {
      cs: {
        translation: cs,
      },
      de: {
        translation: de,
      },
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
    },
    whitelist: ['en', 'es', 'fr', 'it', 'de', 'cs'],
  });

export default i18n;
